@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'DM-Sans';
    src: url('./fonts/DM_Sans/DMSans-VariableFont_opsz\,wght.ttf');
}

:root {
    --wafaa-black: #000;
    --wafaa-black-1: #000;

    --wafaa-purple: #7c37b2;
    --wafaa-purple-2: #7c37b2;

    --wafaa-purple-light: #7000C9;
    --wafaa-purple-lighter:#Ad45ff;
    --wafaa-purple-opacity-50: #7C37B280;

    --wafaa-dark-blue: #110B5C;
    --wafaa-dark-blue-2: #05004E;
    --wafaa-dark-blue-3: #05004E;

    --wafaa-white: #FFFFFF;
    --wafaa-white-2: #FFFFFF;
    --wafaa-white-3: #ffffff;
    --wafaa-white-4: #dbdbdb;

    --wafaa-grey: #8690AB;
    --wafaa-grey-1: #8690AB;
    --wafaa-grey-2: #BBC5CB;
    --wafaa-grey-4: #e9ecef;

    --wafaa-grey-opacity-50: #C4C4C480;
    --wafaa-grey-3: #F4F4F4;

    --wafaa-red: #FF6E6B;
    --wafaa-red-2: #B80303;
    --wafaa-bright-green: #30E52C;
}

.dark {
    --wafaa-black: #000;
    --wafaa-black-1: #fff;

    --wafaa-purple: #7c37b2;
    --wafaa-purple-2: #1f1f1f;

    --wafaa-purple-light: #7000C9;
    --wafaa-purple-lighter:#Ad45ff;
    --wafaa-purple-opacity-50: #7C37B280;

    --wafaa-dark-blue: #110B5C;
    --wafaa-dark-blue-2: #ebebeb;
    --wafaa-dark-blue-3: #ebebeb;

    --wafaa-white: #FFFFFF;
    --wafaa-white-2: #0d0c0c;
    --wafaa-white-3: #030202;
    --wafaa-white-4: #1e1e1e;

    --wafaa-grey: #8690AB;
    --wafaa-grey-1: #fff;
    --wafaa-grey-2: #0a0505;
    --wafaa-grey-4: #0a0505;

    --wafaa-grey-opacity-50: #C4C4C480;
    --wafaa-grey-3: #F4F4F4;
    
    --wafaa-red: #FF6E6B;
    --wafaa-red-2: #B80303;
    --wafaa-bright-green: #30E52C;
    /* Native Bootstrap */
    /* --bs-white-rgb: #000000; */
    /* --bs-dark-rgb: #e4e4e4; */
}

/* Font Section */

.font-family-montserrat {
    font-family: 'Montserrat';
}

.font-family-poppins {
    font-family: 'Poppins';
}

.font-family-dmsans {
    font-family: 'Poppins';
}

.font-size-1 {
    font-size: 1px;
}

.font-size-2 {
    font-size: 2px;
}

.font-size-3 {
    font-size: 3px;
}

.font-size-4 {
    font-size: 4px;
}

.font-size-5 {
    font-size: 5px;
}

.font-size-6 {
    font-size: 6px;
}

.font-size-7 {
    font-size: 7px;
}

.font-size-8 {
    font-size: 8px;
}

.font-size-9 {
    font-size: 9px;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-11 {
    font-size: 11px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-19 {
    font-size: 19px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-25 {
    font-size: 25px;
}

.font-weight-bolder {
    font-weight: bolder;
}

.font-weight-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

/* Height */

.h-5 {
    height: 5%;
}

.h-10 {
    height: 10%;
}

.h-15 {
    height: 15%;
}

.h-20 {
    height: 20%;
}

.h-30 {
    height: 30%;
}

.h-35 {
    height: 35%;
}

.h-40 {
    height: 40%;
}

.h-45 {
    height: 45%;
}

.h-55 {
    height: 55%;
}

.h-60 {
    height: 60%;
}

.h-65 {
    height: 65%;
}

.h-70 {
    height: 70%;
}

.h-80 {
    height: 80%;
}

.h-85 {
    height: 85%;
}

.h-90 {
    height: 90%;
}

.h-95 {
    height: 95%;
}

.height-0 {
    height: 0px !important;
}

.height-5 {
    height: 5px !important;
}

.height-10 {
    height: 10px !important;
}

.height-20 {
    height: 20px !important;
}

.height-30 {
    height: 30px !important;
}

.height-40 {
    height: 40px !important;
}

.height-50 {
    height: 50px !important;
}

.height-60 {
    height: 60px !important;
}

.height-70 {
    height: 70px !important;
}

.height-80 {
    height: 80px !important;
}

.height-90 {
    height: 90px !important;
}

.height-100 {
    height: 100px !important;
}

.height-110 {
    height: 110px !important;
}

.height-120 {
    height: 120px !important;
}

.height-130 {
    height: 130px !important;
}

.height-140 {
    height: 140px !important;
}

.height-150 {
    height: 150px !important;
}

.height-200 {
    height: 200px !important;
}

.height-225 {
    height: 225px !important;
}

.height-250 {
    height: 250px !important;
}

.height-300 {
    height: 300px !important;
}

.height-350 {
    height: 350px !important;
}

.height-400 {
    height: 400px !important;
}

.height-450 {
    height: 450px !important;
}

.height-500 {
    height: 500px !important;
}

.min-height-5 {
    min-height: 5px;
}

.min-height-10 {
    min-height: 10px;
}

.min-height-20 {
    min-height: 20px;
}

.min-height-30 {
    min-height: 30px;
}

.min-height-40 {
    min-height: 40px;
}

.min-height-50 {
    min-height: 50px;
}

.max-height-5 {
    max-height: 5px;
}

.max-height-10 {
    max-height: 10px;
}

.max-height-20 {
    max-height: 20px;
}

.max-height-30 {
    max-height: 30px;
}

.max-height-40 {
    max-height: 40px;
}

.max-height-50 {
    max-height: 50px;
}

.max-height-100 {
    max-height: 100px;
}

.max-height-150 {
    max-height: 150px;
}

.max-height-200 {
    max-height: 200px;
}

/* width */

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.width-5 {
    width: 5px !important;
}

.width-10 {
    width: 10px !important;
}

.width-20 {
    width: 20px !important;
}

.width-30 {
    width: 30px !important;
}

.width-40 {
    width: 40px !important;
}

.width-50 {
    width: 50px !important;
}

.width-60 {
    width: 60px !important;
}

.width-70 {
    width: 70px !important;
}

.width-80 {
    width: 80px !important;
}

.width-90 {
    width: 90px !important;
}

.width-100 {
    width: 100px !important;
}

.width-150 {
    width: 150px !important;
}

.width-200 {
    width: 200px !important;
}

.width-250 {
    width: 250px !important;
}

.width-300 {
    width: 300px !important;
}

.width-350 {
    width: 350px !important;
}

.width-400 {
    width: 400px !important;
}

.width-450 {
    width: 450px !important;
}

.width-500 {
    width: 500px !important;
}

.min-width-50 {
    min-width: 50px;
}

.min-width-100 {
    min-width: 100px;
}

.min-width-150 {
    min-width: 150px;
}

.min-width-200 {
    min-width: 200px;
}

.min-width-250 {
    min-width: 250px;
}

.min-width-300 {
    min-width: 300px;
}

.max-width-50 {
    max-width: 50px;
}

.max-width-100 {
    max-width: 100px;
}

.max-width-150 {
    max-width: 150px;
}

.max-width-200 {
    max-width: 200px;
}

.max-width-250 {
    max-width: 250px;
}

.max-width-300 {
    max-width: 300px;
}

.max-width-350 {
    max-width: 350px;
}

.max-width-600 {
    max-width: 600px;
}

.max-width-700 {
    max-width: 700px;
}


/* left */

.left-0 {
    left: 0px;
}

.left-5 {
    left: 5px;
}

.left-10 {
    left: 10px;
}

.left-15 {
    left: 15px;
}

.left-20 {
    left: 20px;
}

.left-25 {
    left: 25px;
}

.left-30 {
    left: 30px;
}

.left-35 {
    left: 35px;
}

.left-40 {
    left: 40px;
}

.left-45 {
    left: 45px;
}

.left-50 {
    left: 50px;
}

/* right */

.right-0 {
    right: 0px;
}

.right-5 {
    right: 5px;
}

.right-10 {
    right: 10px;
}

.right-15 {
    right: 15px;
}

.right-20 {
    right: 20px;
}

.right-25 {
    right: 25px;
}

.right-30 {
    right: 30px;
}

.right-35 {
    right: 35px;
}

.right-40 {
    right: 40px;
}

.right-45 {
    right: 45px;
}

.right-50 {
    right: 50px;
}

/* bottom */

.bottom-0 {
    bottom: 0px;
}

.bottom-5 {
    bottom: 5px;
}

.bottom--5 {
    bottom: -5px;
}

.bottom-10 {
    bottom: 10px;
}

.bottom--10 {
    bottom: -10px;
}

.bottom-15 {
    bottom: 15px;
}

.bottom--15 {
    bottom: -15px;
}

.bottom-20 {
    bottom: 20px;
}

.bottom--20 {
    bottom: -20px;
}

.bottom-25 {
    bottom: 25px;
}

.bottom--25 {
    bottom: -25px;
}

.bottom-30 {
    bottom: 30px;
}

.bottom--30 {
    bottom: -30px;
}

.bottom-35 {
    bottom: 35px;
}

.bottom--35 {
    bottom: -35px;
}

.bottom-40 {
    bottom: 40px;
}

.bottom-45 {
    bottom: 45px;
}

.bottom-50 {
    bottom: 50px;
}

.bottom-55 {
    bottom: 55px;
}

.bottom-60 {
    bottom: 60px;
}

/* top */

.top-0 {
    top: 0px;
}

.top-5 {
    top: 5px;
}

.top-10 {
    top: 10px;
}

.top-15 {
    top: 15px;
}

.top-20 {
    top: 20px;
}

.top--5 {
    top: -5px;
}

.top--10 {
    top: -10px;
}

.top--15 {
    top: -15px;
}

.top--20 {
    top: -20px;
}

.transition-0-1-ease-in {
    transition: 0.1s ease-in;
}

.transition-0-2-ease-in {
    transition: 0.2s ease-in;
}

.transition-0-3-ease-in {
    transition: 0.3s ease-in;
}

.transition-0-4-ease-in {
    transition: 0.4s ease-in;
}

.transition-0-5-ease-in {
    transition: 0.5s ease-in;
}

.transition-1-ease-in {
    transition: 1s ease-in;
}

.transition-2-ease-in {
    transition: 2s ease-in;
}

.transition-3-ease-in {
    transition: 3s ease-in;
}

.transition-4-ease-in {
    transition: 4s ease-in;
}

.transition-0-1-ease {
    transition: 0.1s ease;
}

.transition-0-2-ease {
    transition: 0.2s ease;
}

.transition-0-3-ease {
    transition: 0.3s ease;
}

.transition-0-4-ease {
    transition: 0.4s ease;
}

.transition-0-5-ease {
    transition: 0.5s ease;
}

.transition-1-ease {
    transition: 1s ease;
}

.transition-2-ease {
    transition: 2s ease;
}

.transition-3-ease {
    transition: 3s ease;
}

.transition-4-ease {
    transition: 4s ease;
}

.transition-5-ease-in {
    transition: 5s ease-in;
}

.transition-0-1-bounce {
    transition: 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-0-2-bounce {
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-0-3-bounce {
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-0-4-bounce {
    transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-0-5-bounce {
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-0-5-bounce {
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-1-bounce {
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-1-5-bounce {
    transition: 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-2-bounce {
    transition: 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-3-bounce {
    transition: 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-4-bounce {
    transition: 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.transition-5-bounce {
    transition: 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.bg-wafaa-purple {
    background-color: var(--wafaa-purple);
}

.bg-wafaa-purple-2 {
    background-color: var(--wafaa-purple-2);
}

.bg-wafaa-danger {
    background-color: var(--wafaa-red);
}

.bg-wafaa-purple-gradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), linear-gradient(0deg, var(--wafaa-purple), var(--wafaa-purple));
}

.border-wafaa-purple {
    border-color: var(--wafaa-purple) !important;
}

.border-wafaa-purple-light {
    border-color: var(--wafaa-purple-light) !important;
}

.border-wafaa-purple-lighter {
    border-color: var(--wafaa-purple-lighter) !important;
}

.border-wafaa-grey-opacity-50 {
    border-color: var(--wafaa-grey-opacity-50) !important;
}

.border-wafaa-grey-2 {
    border-color: var(--wafaa-grey-2) !important;
}

.border-wafaa-grey-4 {
    border-color: var(--wafaa-grey-4) !important;
}

.bg-wafaa-purple-opacity-50 {
    background-color: var(--wafaa-purple-opacity-50) !important;
}

.bg-wafaa-grey {
    background-color: var(--wafaa-grey-3) !important;
}

.bg-wafaa-grey-2 {
    background-color: var(--wafaa-grey-2) !important;
}

.bg-wafaa-grey-4 {
    background-color: var(--wafaa-grey-4) !important;
}

.bg-wafaa-white{
    background-color: var(--wafaa-white);
}

.bg-wafaa-white-2{
    background-color: var(--wafaa-white-2);
}

.bg-wafaa-white-3{
    background-color: var(--wafaa-white-3);
}

.bg-wafaa-white-4{
    background-color: var(--wafaa-white-4);
}

.shadow-wafaa-btn {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shadow-wafaa-btn-inset {
    box-shadow: inset 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.text-wafaa-purple {
    color: var(--wafaa-purple) !important;
}

.text-wafaa-dark-blue {
    color: var(--wafaa-dark-blue) !important;
}

.text-wafaa-dark-blue-2 {
    color: var(--wafaa-dark-blue-2) !important;
}

.text-wafaa-grey {
    color: var(--wafaa-grey) !important;
}

.text-wafaa-grey-1 {
    color: var(--wafaa-grey-1) !important;
}

.text-wafaa-danger {
    color: var(--wafaa-red) !important;
}

.text-wafaa-bright-green {
    color: var(--wafaa-bright-green) !important;
}

.text-wafaa-white {
    color: var(--wafaa-white) !important;
}

.text-wafaa-white-1 {
    color: var(--wafaa-white-1) !important;
}

.text-wafaa-white-2 {
    color: var(--wafaa-white-2) !important;
}

.text-wafaa-white-3 {
    color: var(--wafaa-white-3) !important;
}

.text-wafaa-white-4 {
    color: var(--wafaa-white-4) !important;
}

.text-wafaa-black {
    color: var(--wafaa-black) !important;
}

.text-wafaa-black-1 {
    color: var(--wafaa-black-1) !important;
}


.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.5rem !important;
}

.mt-n3 {
    margin-top: -0.75rem !important;
}

.mt-n4 {
    margin-top: -1rem !important;
}

.mt-n5 {
    margin-top: -1.25rem !important;
}

.p-2-5 {
    padding: 0.75rem !important;
}

.px-2-5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.py-2-5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.form-check-input:focus,
input:focus {
    outline: none;
}

.wafaa-navigation a {
    color: var(--wafaa-grey);
    transition: 0.8s ease-in;
}

.wafaa-navigation a.active,
.wafaa-navigation a.active path {
    color: var(--wafaa-purple);
    fill: var(--wafaa-purple);
}

.fill-wafaa-purple-path path{
    fill: var(--wafaa-purple);
}

.shadow-wafaa {
    box-shadow: 0px 2px 4px -1px rgba(28, 55, 90, 0.16), 0px 3px 12px -1px rgba(28, 52, 84, 0.26);
}

.shadow-wafaa-bg {
    box-shadow: 0px 10px 20px 0px rgba(194, 194, 194, 0.16), 0px 0px 30px 0px rgba(170, 170, 170, 0.16);
}



/* overrides */
.btn.bg-wafaa-purple:active,
.btn.bg-wafaa-purple:hover {
    color: #fff;
    background-color: var(--wafaa-purple);
    border-color: var(--wafaa-purple);
}

.btn.bg-wafaa-purple-2:active,
.btn.bg-wafaa-purple-2:hover {
    color: #fff;
    background-color: var(--wafaa-purple-2);
    border-color: var(--wafaa-purple-2);
}

.btn-check:checked+.btn {
    color: #fff !important;
    background-color: var(--wafaa-purple);
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    border-color: transparent;
}

.form-switch .form-check-input.wafaa-white-switch {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e);
    width: 5em;
}

.form-check-input.wafaa-white-switch {
    height: 2.5em;
    background-color: var(--wafaa-grey-2);
}

.form-check-input:checked {
    border: transparent;
    background-color: var(--wafaa-purple);
}

.form-check-input:focus {
    box-shadow: 0 0 0 0rem rgba(13, 110, 253, .25);
}

.btn-wafaa-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--wafaa-grey-opacity-50);
    --bs-btn-border-color: var(--wafaa-grey-opacity-50);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--wafaa-grey-opacity-50);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--wafaa-grey);
    --bs-btn-active-border-color: var(--wafaa-grey);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--wafaa-grey-opacity-50);
    --bs-btn-disabled-border-color: var(--wafaa-grey-opacity-50);
}

.translate-x-0 {
    transform: translateX(0px);
}

.translate-x--10 {
    transform: translateX(-10px);
}

.translate-x-10 {
    transform: translateX(10px);
}

.translate-x--20 {
    transform: translateX(-20px);
}

.translate-x-20 {
    transform: translateX(20px);
}

.translate-x--30 {
    transform: translateX(-30px);
}

.translate-x-30 {
    transform: translateX(30px);
}

.translate-x--40 {
    transform: translateX(-40px);
}

.translate-x-40 {
    transform: translateX(40px);
}

.translate-x--100 {
    transform: translateX(-100%);
}

.translate-x-100 {
    transform: translateX(100%);
}

.outline-none {
    outline: none;
}

.picker-window {
    height: 2.5em;
    transform: translateY(-40%);
    pointer-events: none;
}

.picker:before,
.picker:after {
    content: "";
    position: absolute;
    width: 100%;
    pointer-events: none;
}

.picker:before {
    top: -0.2em;
}

.picker:after {
    bottom: -0.2em;
}

.picker ul {
    -ms-overflow-style: none;
    max-height: 4em;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
}

.picker ul::-webkit-scrollbar {
    display: none;
}

.picker li {
    scroll-snap-align: center;
    height: 2.5em;
}

.picker-ul{
    -ms-overflow-style: none;
    max-height: 200px;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
}

.picker-ul li {
    scroll-snap-align: center;
    height: 2.5em;
}

.picker-window-ul {
    transform: translateY(-70%);
    pointer-events: none;
}

.button-container {
    text-align: center;
    padding-top: 3rem;
    width: 100%;
}

.number-textfield {
    -moz-appearance: textfield;
}

.number-textfield::-webkit-outer-spin-button,
.number-textfield::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.track {
    appearance: none;
    -webkit-appearance: none;
    height: 2px;
    accent-color: var(--wafaa-purple);
}

.track::-webkit-slider-runnable-track {
    accent-color: var(--wafaa-purple);
    background-color: var(--wafaa-grey-2);
    height: 2px;
    position: relative;
    width: 100%;
    top: -1px;
}

.track::-moz-range-track {
    accent-color: var(--wafaa-purple);
    background-color: var(--wafaa-grey-2);
}

.track::-ms-track {
    cursor: pointer;
    color: transparent;
    border-color: transparent;
}

.track::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
    border: 1px solid var(--wafaa-purple);
    border-radius: 5px;
    transform: rotate(45deg);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    top: -10px;
}

.track::-moz-range-thumb {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
    border: 1px solid var(--wafaa-purple);
    border-radius: 5px;
    transform: rotate(45deg);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.track::-ms-thumb {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
    border: 1px solid var(--wafaa-purple);
    border-radius: 5px;
    transform: rotate(45deg);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.scale {
    list-style: none;
    top: -10px;
}

.step{
    border-radius: 5px;
    transform: rotate(45deg);
}

.filter-grayscale-1{
    filter: grayscale(1);
}

.transform-scale-1-1{
    transform: scale(1.1);
}

.transform-scale-1-2{
    transform: scale(1.2);
}

.transform-scale-1-3{
    transform: scale(1.3);
}

.transform-scale-1-4{
    transform: scale(1.4);
}

.transform-scale-1-5{
    transform: scale(1.5);
}

.cursor-pointer{
    cursor: pointer;
}